import NotFoundPage from '@components/NotFoundPage'

import { graphql } from 'gatsby'

export const query = graphql`
  query {
    allContentful404Page {
      edges {
        node {
          title
          heading
          link {
            url
            label
          }
        }
      }
    }
  }
`

export default NotFoundPage
